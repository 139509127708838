import './bootstrap.js';
import './custom.js';
/*import './report-table-items-fill.js';
import './conclusions-wrapper-fill.js';
import './report-table-arrows-logic.js';
import './report-table-mobile-scroll.js';
import './add-selected-file.js';
import './air-datepicker.js';
import './air-datepicker-bind.js';
import './table-arrow-logic.js';*/
import { createApp } from 'vue';
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();


import Field from './components/report/Field.vue';
import ProfField from './components/profitability/Field.vue';
import Search from './components/report/SearchComponent.vue';
import Row from './components/report/table/Row.vue';
import Column from './components/report/table/Column.vue';
import ColumnRowValue from './components/report/table/ColumnRowValue.vue';
import ReportConditions from './components/report/table/ReportConditions.vue';
import MainReportConditions from './components/report/MainReportConditions.vue';
import Activity from './components/user/Activity.vue';
import Group from './components/user/Group.vue';
import Form from './components/user/Form.vue';
import ChartDataEditor from './components/ChartDataEditor.vue';

const app = createApp();

app.component('field', Field);
app.component('prof-field', ProfField);
app.component('search', Search);
app.component('activity', Activity);
app.component('group', Group);
app.component('vueform', Form);
app.component('report-row', Row);
app.component('report-column', Column);
app.component('column-row-value', ColumnRowValue);
app.component('conditions', ReportConditions);
app.component('mainconditions', MainReportConditions);
app.component('chart-data-editor', ChartDataEditor);

app.mount('#app');
