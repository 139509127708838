<template>
    <div>
        <h6>Метки (ось X)</h6>
        <div v-for="(label, index) in data.labels" :key="'label-' + index" class="d-flex align-items-center mb-2">
            <input v-model="data.labels[index]" class="form-control" placeholder="Введите метку">
            <button type="button" class="btn btn-danger btn-sm ms-2" @click.prevent="removeLabel(index)">✖</button>
        </div>
        <button type="button" class="btn btn-secondary btn-sm mb-2" @click.prevent="addLabel">Добавить метку</button>

        <h6>Линии графика</h6>
        <div v-for="(dataset, index) in data.datasets" :key="'dataset-' + index" class="dataset">
            <input v-model="dataset.label" class="form-control mb-2" placeholder="Название линии">

            <div class="d-flex align-items-center mb-2">
                <input v-model="dataset.color" class="form-control" placeholder="Цвет (HEX-код)" maxlength="7">
                <div class="color-box ms-2" :style="{ backgroundColor: dataset.color }"></div>
            </div>

            <h6>Значение</h6>
            <input v-model="dataset.data" class="form-control mb-2" placeholder="Введите значение">

            <button type="button" class="btn btn-danger btn-sm mt-2" @click.prevent="removeDataset(index)">Удалить линию</button>
        </div>

        <button type="button" class="btn btn-secondary btn-sm mb-2" @click.prevent="addDataset">Добавить линию</button>

        <!-- JSON-данные передаются в скрытое поле формы -->
        <input type="hidden" name="data" :value="jsonString">
    </div>
</template>

<script>
export default {
    props: {
        initialData: {
            type: String,
            default: '{}'
        }
    },
    data() {
        return {
            data: {
                labels: [],
                datasets: []
            }
        };
    },
    computed: {
        jsonString() {
            return JSON.stringify(this.data);
        }
    },
    created() {
        try {
            this.data = JSON.parse(this.initialData) || { labels: [], datasets: [] };
        } catch (e) {
            this.data = { labels: [], datasets: [] };
        }
    },
    methods: {
        addLabel() {
            this.data.labels.push("");
        },
        removeLabel(index) {
            this.data.labels.splice(index, 1);
        },
        addDataset() {
            this.data.datasets.push({ label: "", color: "#000000", data: "" });
        },
        removeDataset(index) {
            this.data.datasets.splice(index, 1);
        }
    }
};
</script>

<style>
.dataset {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
}
.color-box {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
}
</style>
